<template>
  <v-container class="px-0">
    <!-- 입력 -->
    <v-row class="text-center" v-for="question in question_lines" :key="question" no-gutters>
      <v-col>
        <span class="text-h5">{{ question }}</span>
      </v-col>
    </v-row>

    <!-- 답변 -->
    <v-row>
      <v-col cols="6" v-for="(answer, i) in answer_buttons" :key="answer" style="padding: 3px;" >
        <v-card outlined style="border-width: 2px !important;  border-color: black !important;padding: 5px !important;border-color: #F08822 !important;">
          <v-img :src="answer" aspect-ratio="1" @click="onClickAnswer(i)" style="border-width: 2px !important; border-color: #F08822 !important;">
          </v-img>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'ImageAnswers',

    data: () => ({
      question_lines: ["마음에 드는 이미지를 고르세요."],
      answer_buttons: [
      require("@/assets/images/img_q_0.jpg"),
      require("@/assets/images/img_q_1.jpg"),
      require("@/assets/images/img_q_2.jpg"),
      require("@/assets/images/img_q_3.jpg")]
    }),
    methods: {
      onClickAnswer: function(index) {
          
          this.$store.lastQ = index;
          
          this.$emit("pageAction", index);
      }
    }
  }
</script>

<style>

</style>