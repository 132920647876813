<template>
  <v-container class="px-0">
    <!-- 타이틀 및 이미지 섹션 -->
    <v-row no-gutters>
      <v-col>
          <v-card>
              <div class="mx-6 mb-2 black--text text-center pa-3" style="background-color: rgba(255, 255, 255, 255);">
                      <span class="text-h6">{{ title1  }}</span>
                      <BR/>
                      <span class="text-h3">{{ title2 }}</span>
                  </div>
          </v-card>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col>
        <v-img :src="titleImage" aspect-ratio="0.8">
        </v-img>
      </v-col>
    </v-row>
    <!-- 결과 -->
    <v-row>
      <v-col>
          <span>{{ result }}</span>
      </v-col>
    </v-row>

    <!-- 공유하기 영역 -->
  <v-row>
      <v-col>
          <ShareComponent/>
      </v-col>
  </v-row>
  
  <!-- 시작하기 버튼 -->
  <v-row justify="center">
      <v-col class="text-center">
        <v-btn x-large color="primary" width="60%" height="60px" @click="tryAgain">
          <span class="text-h6">시작하기</span>
        </v-btn>
      </v-col>
        <v-col class="text-center">
        <v-btn x-large color="secondary" width="60%" height="60px" @click="moveToMain">
          <span class="text-h6">메인화면</span>
        </v-btn>
      </v-col>

      
    </v-row>
  </v-container>
</template>

<script>
import ShareComponent from '@/components/ShareComponent';

  export default {
    name: 'SharedResultPage',
    components: {
      ShareComponent,
    },
    data: () => ({
      titleImage: "https://www.looper.com/img/gallery/things-only-adults-notice-in-zootopia/l-intro-1601394052.jpg",
      title1: '"MBTI 질문으로 알아보는"',
      title2: "내가 동물이라면?",
    }),
    computed: {
      result: function() {
          return "한반도를 남북으로 관통하는 태풍 ‘카눈’으로 1만 명이 넘는 이웃이 임시대피 중입니다. 중앙재난안전대책본부 등에 따르면, 태풍 ‘카눈’은 30km/h 속도로 북진 중이며, 10일(목) 12시 태풍 강도 ‘중’ (중심기압 980hPa, 최대풍속 29m/s), 강풍반경 약 320km 예상됩니다."
      }
    },
    methods: {
      tryAgain: function() {

      },
      moveToMain: function() {
        
      }
    }
  }
</script>

<style>

</style>