<template>
  <v-container class="px-0">
    <v-row class="text-center" no-gutters>
      <v-col class="mt-4">
        <!-- 공유하기 : -->
        <!-- <v-btn small fab class="mx-2">X</v-btn>
        <v-btn small fab class="mx-2">F</v-btn>
        <v-btn small fab class="mx-2">K</v-btn> -->
        <v-btn small class="mx-2" v-if="linkAddress" @click="onClickCopyLink">공유하기 : <v-icon>mdi-link-variant</v-icon></v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-dialog
        v-model="linkCopyDialog"
        width="350px"
      >
        <v-card class="pt-5 pb-3" rounded="xxl">
          <div class="text-h5 mb-3" style="text-align: center;">링크를 복사 했습니다.</div>
          <v-card-actions>
            <v-btn
              width="100%"
              color="mainColor"
              dark
              style="margin: auto"
              @click="linkCopyDialog = false"
              rounded
            >
              확인
            </v-btn>
          </v-card-actions>
        </v-card>
        </v-dialog>   
    </v-row>
  </v-container>
</template>

<script>
export default {
    name: 'ShareComponent',
    props: ['linkAddress'],
    data: () => ({
      linkCopyDialog: false,
    }),
    methods: {
      onClickCopyLink: function() {
        this.linkCopyDialog = true;
        window.navigator.clipboard.writeText(this.linkAddress);
      }
    }
}
</script>