<template>
  <v-container class="px-0">
    <!-- 타이틀 및 이미지 섹션 -->
    <v-row class="text-center">
      <v-col>
        <span class="title-text">{{ title }}</span>
      </v-col>
    </v-row>
    <v-row class="text-center">
      <v-col>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col >
        <span style="font-size: 0.7rem;">&nbsp;* 입력하신 정보는 설문 외에는 사용 혹은 저장되지 않습니다.</span>
      </v-col>
    </v-row>

    <!-- 이름 입력 -->
    <v-row class="text-center" no-gutters>
      <v-col>
          <v-text-field 
              solo
              v-model="name"
              label="이름"
              :rules="[() => !!name || '이름을 입력하세요', addressCheck]"
              class="custom-text-field" 
              
              clearable>
              
          </v-text-field>
      </v-col>
    </v-row>
    
    <!-- 시작하기 버튼 -->
    <v-row class="text-center" no-gutters>
      <v-col>
        <v-btn x-large color="mainColor" class="text-answer" @click="onClickStart" dark>
          <span class="text-h6">확인 →</span>
        </v-btn>
      </v-col>
    </v-row>
    
  </v-container>
</template>

<script>
  export default {
    name: 'InputName1',

    data: () => ({
      title: '이름을 입력하세요',
      errorMessages: '',
      name: ""
    }),
    methods: {
      addressCheck () {
        this.errorMessages = this.address && !this.name
          ? "이름을 입력하세요."
          : ''

        return true
      },
      onClickStart: function() {
          console.log(this.name);
          if(!this.name) {
            return;
          }
          this.$store.commit('reSetNumber', 0);
          this.$store.commit('setName', this.name);
          this.$emit("pageAction", this.name);
      }
    }
  }
</script>


<style>

.title-text {
  font-size: 30px; /* 원하는 크기로 조절 */
}

/* .custom-text-field {
font-size: 20px;
}


.v-text-field__slot {
  height: 30px;
  vertical-align: middle !important;
}
.v-label {
  height: 30px !important;

}

.custom-text-field .v-messages__message {
  font-size: 15px !important;
  font-weight: bold;
} */
</style>

