<template>
  <v-container class="px-0">
    <!-- 타이틀 및 이미지 섹션 -->
    <v-row no-gutters>
      <v-col>
          <v-card>
                <v-alert outlined color="mainColor" class="text-center">
                    <span class="text-h6">{{ title1 }}</span>
                      <BR/>
                    <span class="text-h4">{{ title2 }}</span>
                </v-alert>
          </v-card>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col>
        <v-img :src="computedTitleImage" contain style="width: 100%;" aspect-ratio="1" @load="loadFinished">
          <!-- 이미지 위에 중앙에 위치할 두 줄의 텍스트 -->
          <v-row class="fill-height" justify="center" style="height: 100%;">
            <v-col class="text-center">
              <div style="background-color: rgba(0, 0, 0, 0.4);">
              <span class="white--text text-h4" style="text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;">[{{ this.$store.state.name }}]님과 <BR/> 어울리는 동물은? </span>
              </div>
            </v-col>
          </v-row>
        </v-img>
      </v-col>
    </v-row>

    <!-- 결과 -->
    <v-row class="mt-5" no-gutters>
      <v-col>
          <span style="white-space: pre-line;">{{ result }}</span>
      </v-col>
    </v-row>

    <!-- 공유하기 영역 -->
  <v-row class="mt-5" no-gutters>
      <v-col>
          <ShareComponent :linkAddress='resultLink'/>
      </v-col>
  </v-row>
  
  <!-- 시작하기 버튼 -->
  <v-row class="mt-5" justify="center" no-gutters>
      <v-col class="text-center px-2" cols="6">
        <v-btn x-large color="mainColor" dark width="100%" height="60px" @click="tryAgain">
          <span class="text-h6">다시하기</span>
        </v-btn>
      </v-col>
        <!-- <v-col class="text-center px-2" cols="6">
        <v-btn x-large color="secondary" width="100%" height="60px" @click="moveToMain">
          <span class="text-h6">메인화면</span>
        </v-btn>
      </v-col> -->
    </v-row>


      <v-overlay :value="loadingOveray" color="white" opacity="1">
        <span class="black--text">{{ loadingText }}</span><br>
        <div class="text-center">
        <v-progress-circular
          indeterminate
          size="50"
          width="6"
          color="mainColor"
        ></v-progress-circular>
      </div>
    </v-overlay>

  </v-container>
</template>

<script>
import ShareComponent from '@/components/ShareComponent';

  export default {
    name: 'ResultPage',
    props: ["resultData", "InputName1"],
    components: {
      ShareComponent,
    },
    data: () => ({
      title1: "MBTI 질문으로 알아보는",
      title2: '내가 만약 "동물"이라면?',
      linkCopyDialog: false,
      loadingOveray: true,
      loadingText: "결과 정리중.",
      resultTexts: {
      case1: `동물로 표현한다면, 당신은 "골든 리트리버" 같아요!

              골든 리트리버는 사람들과의 친화력이 뛰어나고, 따뜻하며 사교적인 성격을 가지고 있죠.
              주변의 모든 사람들을 사랑하는 마음으로 바라보고, 그 누구와도 친구가 될 준비가 되어 있어요.
              그리고 골든 리트리버는 때로는 주인의 명령을 너무 잘 따르려고 애쓰다 보니, 가끔은 다른 사람들의 의견이나 생각을 너무 쉽게 받아들이는 경향이 있어요.

              하지만 재미있는 점은, 골든 리트리버는 자기 자신을 큰 개로 인식하지 못하곤 해요.
              때로는 자기를 작은 강아지로만 생각하기도 하죠.
              이런 모습은 때로 자신을 지나치게 낮추거나, 다른 사람들을 이상화하는 경향과도 비슷하게 느껴집니다.

              아무튼, 따뜻하고 친화력 있는 골든 리트리버처럼, 주위 사람들과의 관계를 중요시하는 사람이라면 그것이 바로 당신의 성격과 가장 비슷할 거에요!`,
      case2: `동물로 표현한다면, 당신은 "사자" 같아요!

              왕성한 카리스마와 리더십, 무엇보다 그 눈부신 자신감으로 사자는 사바나의 왕으로 불리죠.
              늘 앞장서서 주변 동물들을 지휘하며, 그 영역을 지키는 모습은 당신의 특성과 너무나도 닮아있습니다.
              다만, 사자는 때로는 너무 큰 자신감 때문에 다른 동물들을 위협하기도 해요. 그런 모습은 당신이 냉철하게 이성적 사고를 할 때와 비슷하게 느껴집니다.

              아, 그리고 재밌는 사실 하나! 사자는 사바나의 동물 중에서는 그렇게 흔하지 않아요. 이건 당신이 특별하다고 생각하면 된답니다.

              그럼 결론적으로, 마치 사바나의 왕인 사자처럼, 주변에서는 어렵게 찾아볼 수 있는 뛰어난 리더십을 가진 사람이라면 그것이 바로 당신이겠죠!`
              ,
      case3: `동물로 표현한다면, 당신은 "앵무새" 같아요!

              앵무새는 말도 잘하고, 화려한 깃털로 주변의 주목을 받는 동물이죠.
              그렇게 매력적이면서도, 사람들과 끈끈한 유대 관계를 맺는 것을 좋아해요.
              또, 그들은 언제나 주변을 활기차게 만들어, 분위기 메이커가 되곤 합니다.
              이런 특성들은 당신이 사회적이고 정서적으로 깊은 관계를 중요시한다는 것과도 잘 맞아떨어져요.

              그리고 재미있게도, 앵무새는 동물원이나 애완 동물 가게에서 꽤 쉽게 볼 수 있는 동물이에요.
              이건 당신이 어느 모임을 가든 쉽게 만날 수 있다는 점과도 닮아 있네요.

              결론적으로, 매력 넘치는 앵무새처럼 주변 사람들과 깊은 관계를 맺는 것을 중요시하는 사람이라면, 바로 당신의 성격을 떠올리게 되겠죠!`
              ,
      case4: `동물로 표현한다면, 당신은 "여우" 같아요!

              여우는 똑똑하고 교활한 동물로 알려져 있죠.
              그들은 때로는 다른 동물들을 교묘하게 속여서 목표를 달성하는데, 이는 당신이 논쟁이나 정신적 고문을 즐기면서 상대의 의견을 꼬집는 스타일과도 잘 어울립니다.
              여우는 자신의 아이디어나 계획을 실현시키기 위해 다양한 전략을 세워 나가는 모습이 있어요.
              그런데 재미있는 점은, 여우도 그저 재미를 위해 목표를 추구하기도 해요.

              아, 그리고 여우는 자신만의 독특한 방식으로 환경에 적응하며, 때로는 주변 동물들과는 다른 방식으로 생각하고 행동하는데,
              이는 당신의 아웃사이더적인 성격과도 비슷하게 느껴집니다.

              그러니까, 똑똑하고 교활하게 주변 환경에 적응하며 목표를 추구하는 여우처럼, 당신도 주변에서는 찾아보기 힘든 독특한 성격을 가지고 있겠죠!`
              ,
      case5: `동물로 표현한다면, 당신은 "돌고래" 같아요!

              돌고래는 알려져 있듯이 굉장히 사교적이고 장난기 많은 동물이죠.
              그들은 종종 서로의 흥분된 감정을 공유하며, 바다에서 즉흥적으로 놀기도 해요.
              이런 모습은 당신이 갑자기 흥얼거리며 춤을 추는 모습과도 잘 어울립니다.
              그리고 돌고래는 그룹 속에서 서로를 도와주고 위로하는 모습을 보이기도 하죠.
              이는 당신이 주변 사람들을 위로하고 용기를 북돋아 주는 성격과도 흡사합니다.

              아, 그리고 놀라운 점은, 돌고래는 그 특유의 매력과 지능으로 많은 사람들의 마음을 사로잡는 동물이에요.
              이것은 당신의 거부할 수 없는 매력과도 비슷하게 느껴집니다.

              그러니까, 바다의 별, 돌고래처럼 주변 사람들과 잘 어울리며 긍정적인 에너지를 전파하는 사람이라면, 바로 당신의 성격을 상상하게 되겠죠!`
              ,
      case6: `동물로 표현한다면, 당신은 "펭귄" 같아요!

              펭귄은 그룹에서의 생활을 중요시하고, 서로에게 깊은 애정과 관심을 보이는 동물이죠.
              그들은 서로를 돌보며, 특히 동료에게 필요한 순간에는 믿음직하게 옆에 서주는 모습이 보입니다.
              이런 특성은 당신이 사교 모임에서 주위 사람들에게 관심과 애정을 보이는 모습과도 잘 어울립니다.

              그리고 재미있는 사실은, 펭귄은 그룹에서 활발한 모습으로 여러 동물들의 주목을 받기도 하죠.
              이것은 당신이 분위기를 좌지우지하며 스포트라이트를 받는 모습과도 흡사하답니다.

              그럼 결론적으로, 항상 주변 사람들과 활발하게 어울리며, 서로의 사이를 더 따뜻하게 만드는 펭귄처럼, 당신도 주변에서는 찾아보기 힘든 따뜻하고 친화력 있는 성격을 가지고 있겠죠!`
              ,
      case7: `동물로 표현한다면, 당신은 "원숭이" 같아요!

              원숭이는 재치 있고 활발한 동물로, 항상 그룹에서 중심 역할을 하며 다른 동물들의 주목을 받곤 해요.
              그들은 종종 농담거리가 되며, 나무 위에서 이리저리 뛰어다니면서 주변을 웃게 만들죠.
              이런 모습은 당신이 직설적이면서도 친근한 농담으로 주변 사람을 웃게 만드는 성격과도 잘 어울립니다.

              그리고 놀라운 점은, 원숭이는 무대나 주목을 받는 상황에서 즉시 주역이 되려고 앞장서는 경향이 있어요.
              이것은 당신이 무대에 올라올 사람을 호명하는 경우, 망설임 없이 무대에 올라서는 모습과도 비슷하게 느껴집니다.

              그럼 결론적으로, 항상 주변 사람들의 주목을 받으며 중심 역할을 하는 원숭이처럼, 당신도 주변에서는 찾아보기 힘든 활발하고 재치 있는 성격을 가지고 있겠죠!`
              ,
      case8: `동물로 표현한다면, 당신은 "늑대" 같아요!

              늑대는 사회적인 동물로, 그들의 무리 안에서 확고한 질서와 계급이 있죠.
              그들은 무리의 안전과 통일을 위해 함께 작동하며, 리더십을 발휘하는 알파 늑대는 무리를 안전하게 인도하곤 합니다.
              이런 모습은 당신이 사회나 가족을 하나로 단결시키려고 노력하며, 필요한 질서를 정립하는 성격과도 잘 어울립니다.

              그리고 재밌는 사실은, 늑대는 그들의 무리와 함께 사냥을 하며, 무리의 모든 구성원이 협력하여 목표를 달성합니다.
              이것은 당신이 사회 운동이나 지역 사회 발전을 위한 활동에서 사람들을 모으는 역할을 하는 모습과도 비슷하게 느껴집니다.

              그럼 결론적으로, 무리의 통일과 질서를 중시하며 리더십을 발휘하는 늑대처럼, 당신도 주변에서는 찾아보기 힘든 리더십과 조직력을 가진 사람이겠죠!`
              ,
      case9: `동물로 표현한다면, 당신은 "고양이" 같아요!

              고양이는 종종 독립적이고 내성적인 성격으로 알려져 있죠.
              그들은 자신만의 세상에서 살아가며, 첫 눈에는 다가가기 어려운 면도 있어요.
              하지만 그 안에는 누구보다도 따뜻하고 애정 가득한 마음이 숨어 있습니다.
              이런 모습은 당신이 침착하고 수줍음이 많은 사람처럼 보이지만, 사실은 불만을 지피면 활활 타오를 수 있는 열정을 가진 성격과도 잘 어울립니다.

              그리고 또 다른 흥미로운 점은, 고양이는 신뢰하는 사람에게만 진정한 모습을 보여주곤 해요.
              이것은 당신이 마음이 맞는 사람을 만났을 때만 그 안에 숨겨진 영감과 즐거움을 공유하는 모습과도 비슷하게 느껴집니다.

              그럼 결론적으로, 자신만의 세상에서 따뜻한 마음을 간직하며 신뢰하는 이에게만 진정한 모습을 보여주는 고양이처럼, 당신도 주변에서는 찾아보기 힘든 깊은 성격의 소유자겠죠!`
              ,
      case10: `동물로 표현한다면, 당신은 "올빼미" 같아요!

              올빼미는 고요한 밤의 대표적인 상징이죠.
              그들은 깊은 통찰력과 뛰어난 직관력으로 밤의 세계를 지배하며, 그 안에서 그들만의 세상을 만들어냅니다.
              이런 모습은 당신이 창의력을 발휘하며, 강한 직관력으로 주변에 영향력을 미치는 성격과도 잘 어울립니다.

              또한, 올빼미는 독특한 특성을 가진 동물로, 그들의 깊은 눈은 많은 것을 바라보며, 주변 조건을 경시하지 않고 숲을 보는 특성이 있어요.
              이것은 당신이 주변적인 조건들을 경시하며 목적을 달성하는 성격과도 흡사하답니다.

              그럼 결론적으로, 깊은 통찰력과 독립적인 성격으로 밤의 세계를 지배하는 올빼미처럼, 당신도 주변에서는 찾아보기 힘든 깊이와 독창성을 가진 사람이겠죠!`
              ,
      case11: `동물로 표현한다면, 당신은 "거북이" 같아요!

              거북이는 주변 환경에 대해 깊은 통찰력을 갖고 있어, 느린 움직임 속에도 끈기와 지속성으로 목표를 향해 나아갑니다.
              이런 모습은 당신이 논리와 분석으로 문제를 해결하는 성격과도 잘 어울립니다.

              또한, 거북이는 그들의 등껍질 속에 숨어 있으면서 주변을 관찰하곤 해요.
              이것은 당신이 조용하고 과묵하게 관심 분야에 대한 깊은 사색을 가지는 성격과도 닮아있답니다.

              그럼 결론적으로, 깊은 통찰력과 끈기로 목표를 향해 나아가는 거북이처럼, 당신도 주변에서는 찾아보기 힘든 깊이와 논리력을 가진 사람이겠죠!`
              ,
      case12: `동물로 표현한다면, 당신은 "비버" 같아요!

              비버는 자신의 능력과 기술을 이용하여 놀라운 댐을 만들어냅니다.
              그들은 환경을 직접 조작하고, 문제를 해결하는 데 있어서는 열정적입니다.
              이런 모습은 당신이 직접 손으로 만지고 눈으로 보면서 세상을 탐색하는 성격과도 잘 어울립니다.

              또한, 비버는 주변 환경을 활용하여 새로운 것을 만들어내는 데 뛰어난 능력을 지니고 있어요.
              이것은 당신이 취미로 만드는 것을 좋아하며, 새로운 기술을 터득해 나가는 모습과도 흡사합니다.

              그럼 결론적으로, 자신의 능력과 기술로 놀라운 작품을 만들어내는 비버처럼, 당신도 주변에서는 찾아보기 힘든 기술과 능력을 가진 사람이겠죠!`
              ,
      case13: `동물로 표현한다면, 당신은 "사슴" 같아요!

              사슴은 그 아름다움과 고요한 성질로 알려져 있습니다.
              말 없이 조용히 숲속을 걷는 그들의 모습은 내면의 깊은 감정을 가진 당신과 어울립니다.
              사슴은 주변의 위협을 민감하게 감지하고, 그로 인해 때로는 예민하게 반응하기도 합니다.
              이는 당신이 타인의 감정에 민감하게 반응하는 성격과도 흡사하죠.

              또한, 사슴은 외부에서 보기에는 조용하게 자신의 일만 하는 것처럼 보이지만, 사실 그들은 항상 주변 상황을 철저하게 파악하고 있어요.
              그럼 결론적으로, 조용하고 민감하며 내면의 깊은 감정을 지닌 사슴처럼, 당신도 주변에서는 찾아보기 힘든 깊이와 감성을 가진 사람이겠죠!`
              ,
      case14: `당신이 만약 동물이었다면, 분명 "코알라" 일거예요!

              왜냐하면 코알라는 조용하고 차분한 성격의 동물로 알려져 있죠.
              그들은 나무 위에서 무얼하느냐면, 대부분의 시간을 먹고, 잠자고, 휴식하는 데에 보내요.
              소소한 일상 속에서 행복을 찾는 모습이 바로 당신과 닮았답니다.

              코알라는 그들의 지역, 그리고 가족을 굉장히 아끼는 동물입니다.
              주변의 이들에게 많은 애정을 보이죠. 그렇게 주변 사람들과 따뜻한 관계를 유지하는 모습, 그리고 자신의 장소에서 안정감을 느끼는 모습이 바로 당신과 일치해요.

              그래서, 조용하고 차분하며 주변 사람들을 아끼는 당신, 코알라처럼 누구나 좋아하는 존재가 아닐까 생각해봅니다!`
              ,
      case15: `당신이 만약 동물이었다면, 분명 "말" 일거예요!

              말은 성실하고 강한 논리로 일을 처리하는 동물로 알려져 있어요.
              그들은 정확하게 그들 앞에 놓인 임무를 완수하기 위해 꾸준히 노력하죠. 그게 바로 당신의 모습이에요.

              말은 그들의 주인과의 관계에서 큰 충성심을 보이며, 그들의 임무를 성실하게 수행합니다.
              그렇게 꾸준히 일하는 모습, 그리고 그들의 주변 환경에 대한 책임감이 바로 당신과 일치해요.

              그리고 말로서의 당신, 항상 정확하고 신중하게 일을 처리하는 모습으로 모두에게 믿음을 주겠죠.
              헌신적이고 성실한 당신, 이 세상에는 당신처럼 일하는 동물이 말 뿐이에요!`
              ,
      case16: `당신은 바로 "독수리"입니다!

              왜 독수리냐고요? 독수리는 그 높은 하늘에서 모든 것을 선명하게 보는 눈을 가지고 있어요.
              그 눈은 깊고 넓은 지식과 통찰력을 상징하죠. 아무래도 당신이 어릴 때부터 빼어난 지식과 통찰력을 가지고 있어 '책벌레'라는 소리를 듣던 그 모습, 바로 독수리와 닮았어요.

              또, 독수리는 그 지대한 날개로 하늘을 나는 동안 주변의 불필요한 잡음에서 멀리 떨어져 있죠.
              당신도 별 볼 일 없는 잡담보다는, 본인의 분야에서 전략을 세우는 일을 선호하는 모습이 그대로 닮아있어요.

              독수리처럼 당신은 높은 곳에서 보는 큰 그림을 중요하게 생각하며, 또 그 지식을 공유하려는 욕구를 가지고 있죠.
              자, 독수리로 변신하여 그 높은 하늘을 나아보세요!`
              ,
      case17: `이럴수가...!! 당신은..?`
      }
    }),
    computed: {
      resultLink: function() {
        let url = window.location.origin + window.location.pathname;
        return url + `?name=${this.$store.state.name}&result=${this.resultData}`;
      },
      computedTitleImage: function() {
        let lastQ = parseInt(this.resultData / 10000);

        switch (this.resultData % 10000) {
          case 0:
          {
              if (lastQ == 0)
                return require("@/assets/images/0_0.jpg");
              else if (lastQ == 1)
                return require("@/assets/images/0_1.jpg");
              else if (lastQ == 2)
                return require("@/assets/images/0_2.jpg");
              else
                return require("@/assets/images/0_3.jpg");
          }
          case 1:
          {
              if (lastQ == 0)
                return require("@/assets/images/1_0.jpg");
              else if (lastQ == 1)
                return require("@/assets/images/1_1.jpg");
              else if (lastQ == 2)
                return require("@/assets/images/1_2.jpg");
              else
                return require("@/assets/images/1_3.jpg");
          }
          case 10:
          {
              if (lastQ == 0)
                return require("@/assets/images/10_0.jpg");
              else if (lastQ == 1)
                return require("@/assets/images/10_1.jpg");
              else if (lastQ == 2)
                return require("@/assets/images/10_2.jpg");
              else
                return require("@/assets/images/10_3.jpg");
          }
          case 11:
          {
              if (lastQ == 0)
                return require("@/assets/images/11_0.jpg");
              else if (lastQ == 1)
                return require("@/assets/images/11_1.jpg");
              else if (lastQ == 2)
                return require("@/assets/images/11_2.jpg");
              else
                return require("@/assets/images/11_3.jpg");
          }
          case 100:
          {
              if (lastQ == 0)
                return require("@/assets/images/100_0.jpg");
              else if (lastQ == 1)
                return require("@/assets/images/100_1.jpg");
              else if (lastQ == 2)
                return require("@/assets/images/100_2.jpg");
              else
                return require("@/assets/images/100_3.jpg");
          }
          case 101:
          {
              if (lastQ == 0)
                return require("@/assets/images/101_0.jpg");
              else if (lastQ == 1)
                return require("@/assets/images/101_1.jpg");
              else if (lastQ == 2)
                return require("@/assets/images/101_2.jpg");
              else
                return require("@/assets/images/101_3.jpg");
          }
          case 110:
          {
              if (lastQ == 0)
                return require("@/assets/images/110_0.jpg");
              else if (lastQ == 1)
                return require("@/assets/images/110_1.jpg");
              else if (lastQ == 2)
                return require("@/assets/images/110_2.jpg");
              else
                return require("@/assets/images/110_3.jpg");
          }
          case 111:
          {
              if (lastQ == 0)
                return require("@/assets/images/111_0.jpg");
              else if (lastQ == 1)
                return require("@/assets/images/111_1.jpg");
              else if (lastQ == 2)
                return require("@/assets/images/111_2.jpg");
              else
                return require("@/assets/images/111_3.jpg");
          }
          case 1000:
          {
              if (lastQ == 0)
                return require("@/assets/images/1000_0.jpg");
              else if (lastQ == 1)
                return require("@/assets/images/1000_1.jpg");
              else if (lastQ == 2)
                return require("@/assets/images/1000_2.jpg");
              else
                return require("@/assets/images/1000_3.jpg");
          }
          case 1001:
          {
              if (lastQ == 0)
                return require("@/assets/images/1001_0.jpg");
              else if (lastQ == 1)
                return require("@/assets/images/1001_1.jpg");
              else if (lastQ == 2)
                return require("@/assets/images/1001_2.jpg");
              else
                return require("@/assets/images/1001_3.jpg");
          }
          case 1010:
          {
              if (lastQ == 0)
                return require("@/assets/images/1010_0.jpg");
              else if (lastQ== 1)
                return require("@/assets/images/1010_1.jpg");
              else if (lastQ == 2)
                return require("@/assets/images/1010_2.jpg");
              else
                return require("@/assets/images/1010_3.jpg");
          }
          case 1011:
          {
              if (lastQ == 0)
                return require("@/assets/images/1011_0.jpg");
              else if (lastQ == 1)
                return require("@/assets/images/1011_1.jpg");
              else if (lastQ == 2)
                return require("@/assets/images/1011_2.jpg");
              else
                return require("@/assets/images/1011_3.jpg");
          }
          case 1100:
          {
              if (lastQ == 0)
                return require("@/assets/images/1100_0.jpg");
              else if (lastQ == 1)
                return require("@/assets/images/1100_1.jpg");
              else if (lastQ == 2)
                return require("@/assets/images/1100_2.jpg");
              else
                return require("@/assets/images/1100_3.jpg");
          }
          case 1101:
          {
              if (lastQ == 0)
                return require("@/assets/images/1101_0.jpg");
              else if (lastQ == 1)
                return require("@/assets/images/1101_1.jpg");
              else if (lastQ == 2)
                return require("@/assets/images/1101_2.jpg");
              else
                return require("@/assets/images/1101_3.jpg");
          }
          case 1110:
          {
              if (lastQ == 0)
                return require("@/assets/images/1110_0.jpg");
              else if (lastQ == 1)
                return require("@/assets/images/1110_1.jpg");
              else if (lastQ == 2)
                return require("@/assets/images/1110_2.jpg");
              else
                return require("@/assets/images/1110_3.jpg");
          }
          case 1111:
          {
              if (lastQ == 0)
                return require("@/assets/images/1111_0.jpg");
              else if (lastQ == 1)
                return require("@/assets/images/1111_1.jpg");
              else if (lastQ == 2)
                return require("@/assets/images/1111_2.jpg");
              else
                return require("@/assets/images/1111_3.jpg");
          }
          default:
            return "https://cdn.discordapp.com/attachments/1077782115552346185/1145621121102778408/ssowon123_X_4ccd0e04-8f48-4b52-a3b2-457668e7db9a.png";
        }
      },

      result: function() {
          switch (this.resultData % 10000){
            case 0:
              return this.resultTexts.case8;
            case 1:
              return this.resultTexts.case7;
            case 10:
              return this.resultTexts.case6;
            case 11:
              return this.resultTexts.case5;
            case 100:
              return this.resultTexts.case2;
            case 101:
              return this.resultTexts.case4;
            case 110:
              return this.resultTexts.case1;
            case 111:
              return this.resultTexts.case3;
            case 1000:
              return this.resultTexts.case15;
            case 1001:
              return this.resultTexts.case12;
            case 1010:
              return this.resultTexts.case14;
            case 1011:
              return this.resultTexts.case13;
            case 1100:
              return this.resultTexts.case16;
            case 1101:
              return this.resultTexts.case11;
            case 1110:
              return this.resultTexts.case10;
            case 1111:
              return this.resultTexts.case9;
            default:
              return this.resultTexts.case17;
          }
      }
    },
    mounted() {
      let repeatTime = 350;
      let updateText = () => {
        this.loadingText = this.loadingText + ".";
        setTimeout(updateText, repeatTime);
      };
      setTimeout(updateText, repeatTime);
    },
    methods: {
      tryAgain: function() {
          this.$emit("startAgain");
      },
      moveToMain: function() {
        
      },
      loadFinished: function() {
        setTimeout(() => {
          this.loadingOveray = false;
        }, 1000)
      }
    }
  }
</script>

<style>

</style>