<template>
  <v-app>
    <v-main>
      <v-container v-if="current_page > 0 && current_page <= page_count" style="max-width: 550px;" class="">
        <v-row no-gutters>
          <v-col>
            <v-progress-linear
              color="mainColor"
              height="10"
              :value="(current_page / page_count) * 100"
              striped
            ></v-progress-linear>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <div style="text-align: right; font-size: 0.8rem;">{{ current_page }} / {{ page_count }}</div>
          </v-col>
        </v-row>
      </v-container>

      <v-container style="max-width: 550px;" class="" v-if="current_page == 0" >
        <v-row no-gutters>
          <v-col >
            <EntrancePage @pageAction="onPageAction"/>
          </v-col>
        </v-row>
      </v-container>

      <v-container style="max-width: 550px;" class="">
        <v-row no-gutters>
          <v-col>
            <InputName1 @pageAction="onPageAction" v-if="current_page == 1"/>
            <TextQuestion1 @pageAction="onPageAction" v-if="current_page == 2"/>
            <TextQuestion2 @pageAction="onPageAction" v-if="current_page == 3"/>
            <TextQuestion3 @pageAction="onPageAction" v-if="current_page == 4"/>
            <TextQuestion4 @pageAction="onPageAction" v-if="current_page == 5"/>
            <TextQuestion5 @pageAction="onPageAction" v-if="current_page == 6"/>
            <TextQuestion6 @pageAction="onPageAction" v-if="current_page == 7"/>
            <TextQuestion7 @pageAction="onPageAction" v-if="current_page == 8"/>
            <TextQuestion8 @pageAction="onPageAction" v-if="current_page == 9"/>
            <TextQuestion9 @pageAction="onPageAction" v-if="current_page == 10"/>
            <TextQuestion10 @pageAction="onPageAction" v-if="current_page == 11"/>
            <TextQuestion11 @pageAction="onPageAction" v-if="current_page == 12"/>
            <TextQuestion12 @pageAction="onPageAction" v-if="current_page == 13"/>
            <ImageAnswers @pageAction="onPageAction" v-if="current_page == 14"/>
            <ResultPage @pageAction="onPageAction" @startAgain="onStartAgain" :resultData="resultData" v-if="current_page == 15"/>
            <SharedResultPage v-if="is_shared"/>
          </v-col>
        </v-row>
      </v-container>

    </v-main>
  </v-app>
</template>

<style >
#app {
  display: flex;
  justify-content: center;
  align-items: center;
} 


</style>


<script>
import EntrancePage from './views/EntrancePage';
import InputName1 from './views/InputName1';
import TextQuestion1 from './views/TextQuestion1';
import TextQuestion2 from './views/TextQuestion2';
import TextQuestion3 from './views/TextQuestion3';
import TextQuestion4 from './views/TextQuestion4';
import TextQuestion5 from './views/TextQuestion5';
import TextQuestion6 from './views/TextQuestion6';
import TextQuestion7 from './views/TextQuestion7';
import TextQuestion8 from './views/TextQuestion8';
import TextQuestion9 from './views/TextQuestion9';
import TextQuestion10 from './views/TextQuestion10';
import TextQuestion11 from './views/TextQuestion11';
import TextQuestion12 from './views/TextQuestion12';
import ImageAnswers from './views/ImageAnswers';
import ResultPage from './views/ResultPage';
import SharedResultPage from './views/SharedResultPage';

export default {
  name: 'App',

  components: {
    EntrancePage,
    InputName1,
    TextQuestion1,
    TextQuestion2,
    TextQuestion3,
    TextQuestion4,
    TextQuestion5,
    TextQuestion6,
    TextQuestion7,
    TextQuestion8,
    TextQuestion9,
    TextQuestion10,
    TextQuestion11,
    TextQuestion12,
    ImageAnswers,
    ResultPage,
    SharedResultPage
  },

  data: () => ({
    resultData: "",
    current_page: 0,
    page_count: 14,
    is_shared: false,
  }),
  computed: {
    query() {
      return Object.fromEntries(new URLSearchParams(window.location.search));
    }
  },
  mounted() {
    if(Object.prototype.hasOwnProperty.call(this.query, "result")) {
      if(Object.prototype.hasOwnProperty.call(this.query, "name")) {
        this.$store.commit('setName', this.query['name']);
      }
      this.current_page = this.page_count + 1;
      this.resultData = parseInt(this.query["result"]);
    }
  },
  methods: {
    onPageAction(action) {
      this.current_page += 1;
      this.input_data += action;
      this.resultData = this.$store.getters.getTotalNumber + (this.$store.lastQ * 10000);
    },
    onStartAgain() {
      window.location.search = '';
      this.input_data = "";
      this.current_page = 0;
    }
  }
};
</script>
