<template>
    <v-container class="px-0">
      <!-- 입력 -->
      <v-row>
        <v-col>
          <v-card outlined class="text-question-box">
            <v-row class="text-center" v-for="question in question_lines" :key="question" no-gutters>
              <v-col>
                <span class="text-question">{{ question }}</span>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>

      <!-- 답변 -->
      <v-row class="text-center mt-5" v-for="(answer, i) in answer_buttons" :key="answer" no-gutters>
        <v-col>
          <v-btn class="text-answer" color="mainColor" dark x-large @click="onClickAnswer(i)">{{ answer }}</v-btn>
        </v-col>
      </v-row>

    </v-container>
</template>
  
<script>
  export default {
    name: 'TextQuestion',

    data: () => ({
      question_lines: ["나 때문에 남이 빛나면,", "내가 빛날 때보다 더 기분이 좋아!"],
      answer_buttons: ["그렇다.", "아니다."]
    }),
    methods: {
      onClickAnswer: function(index) {
        if (index === 0) {
          this.$store.commit('setNumber3', 1);
        } else if (index === 1) {
          this.$store.commit('setNumber3', -1);
        }
        
        this.$emit("pageAction", index);
      }
    }
  }
</script>
  
  <style>
  
  </style>