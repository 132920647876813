import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    name: '',
    number1: 0,
    number2: 0,
    number3: 0,
    number4: 0,

    lastQ: 0
  },
  mutations: {
    setName(state, name) {
      state.name = name;
    },
    setNumber1(state, number) {  // 2. number 변수를 수정하는 mutation 추가
      if (typeof number === 'number') {  // 값이 정수인지 확인
        state.number1 += number;
      }
    },
    setNumber2(state, number) {  // 2. number 변수를 수정하는 mutation 추가
      if (typeof number === 'number') {  // 값이 정수인지 확인
        state.number2 += number;
      }
    },
    setNumber3(state, number) {  // 2. number 변수를 수정하는 mutation 추가
      if (typeof number === 'number') {  // 값이 정수인지 확인
        state.number3 += number;
      }
    },
    setNumber4(state, number) {  // 2. number 변수를 수정하는 mutation 추가
      if (typeof number === 'number') {  // 값이 정수인지 확인
        state.number4 += number;
      }
    },
    setlastQ(state, number) {  // 2. number 변수를 수정하는 mutation 추가
      if (typeof number === 'number') {  // 값이 정수인지 확인
        state.lastQ = number;
      }
    },

    reSetNumber(state, number) {
      if (typeof number === 'number') {  // 값이 정수인지 확인
        state.number1 = number;
        state.number2 = number;
        state.number3 = number;
        state.number4 = number;
        state.lastQ = number;
      }
    }
  },
  getters: {
    getTotalNumber: state => {
      let number1, number2, number3, number4;

      if(state.number1 < 0)
        number1 = 0;
      else
        number1 = 1000;

      if(state.number2 < 0)
        number2 = 0;
      else
        number2 = 100;

      if(state.number3 < 0)
        number3 = 0;
      else
        number3 = 10;

      if(state.number4 < 0)
        number4 = 0;
      else
        number4 = 1;

      return number1 + number2 + number3 + number4;
    }
  }
})