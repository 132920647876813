<template>
  <v-container class="px-0">
    <!-- 타이틀 및 이미지 섹션 -->
    <v-row no-gutters>
      <v-col>
        <v-img :src="titleImage" aspect-ratio="0.8">
            <v-row class="ma-0" style="position: absolute; bottom: 0; width: 100%">
            <v-col style="padding: 0">
              <div class="mx-6 mb-2 black--text text-center pa-3" style="background-color: rgba(255, 255, 255, 255);">
                <span class="text-h6">{{ title1  }}</span>
                <BR/>
                <v-row v-for="title in title2" :key="title" no-gutters>
                  <v-col>
                    <span style="font-size: 2.2rem; line-height: 1.2;">{{ title }}</span>    
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
        </v-img>
      </v-col>
    </v-row>
    <!-- 시작하기 버튼 -->
    <v-row class="text-center">
      <v-col>
        <v-btn x-large color="mainColor" class="text-answer white--text" height="80px" @click="onClickStart" :style="{ 'border-radius': '10px' }">
          <span class="text-h4">시작하기</span>
        </v-btn>
      </v-col>
    </v-row>
  <!-- 공유하기 영역 -->
  <!-- <ShareComponent/> -->
  </v-container>
</template>

<script>
// import ShareComponent from '@/components/ShareComponent';


  export default {
    name: 'EntrancePage',
    components: {
      // ShareComponent,
    },
    data: () => ({
      //titleImage: "https://www.looper.com/img/gallery/things-only-adults-notice-in-zootopia/l-intro-1601394052.jpg",
      titleImage: require("@/assets/images/main.jpg"),
      title1: 'MBTI 질문으로 알아보는',
      title2: ["내가 만약", '"동물"이라면?']
    }),
    methods: {
      onClickStart: function() {
        this.$emit("pageAction", "start");
      }
    }
  }
</script>

<style>

</style>